import { useCallback, useState } from "react"
import { Web3Provider } from "@ethersproject/providers/lib/web3-provider"
import { useWeb3React } from "@web3-react/core"
import { toast } from "react-toastify"

import { injectedConnector } from "../../wallet/connect"
import { checkChainId } from "../../wallet/ConnectWallet"
import { apiConfig } from "../../api/Api.config"

export const useWallet = () => {
  const { chainId, account, activate, active, library, deactivate } = useWeb3React<Web3Provider>()
  const [loading, setLoading] = useState(false)

  const connect = useCallback(async () => {
    setLoading(true)
    
    try {
      let wallet = ''
      
      await checkChainId()
      await activate(injectedConnector, undefined, true).then(() => {
        const { ethereum } = window as any
        wallet =  ethereum.selectedAddress
      })

      return wallet
    } catch (e: any) {
      console.log(e)
      if (e.code === -32002)
        return toast.warning("Metamask is already running")

      toast.error(e?.message)
      throw new Error("error")
    } finally {
      setLoading(false)
    }
  }, [])

  const verificarWallet = useCallback(async (wallet: string) => {
    setLoading(true)
    try {
      const res = await apiConfig.get(`/validatewallet/${wallet}`)

      if (!res.data?.data) return false

      setLoading(false)

      return res.data.data
    } catch (error) {
      setLoading(false)
      return false
    }
  }, [])

  async function varifyAdmin(wallet: string) {
    setLoading(true)
    try {
      const res = await apiConfig.get(`/validatewallet/${wallet}`)
      const role = res.data.data.role

      setLoading(false)

      if (role === "1001") return {
        isAdmin: true,
        id: res.data.data._id
      }
      if (role === "1002") return {
        isAdmin: false,
        id: res.data.data._id
      }

      return {
        isAdmin: false,
        id: null
      }
    } catch (error) {
      setLoading(false)
      return false
    }
  }

  async function disconnect() {
    try {
      await deactivate()
    } catch (error) {
      toast.error('Pleas Try later')
    }
  }

  return {
    active,
    account,
    chainId,
    library,
    loading,
    connect,
    verificarWallet,
    varifyAdmin,
    disconnect
  }
}
