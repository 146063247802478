import { useState } from "react"
import { toast } from "react-toastify"

import { BasicCardContainer } from "../../../app/Custom"
import { Grafic } from './Grafic'
import { useAppSelector, useRequest } from "../../../app/hooks"
import { SuverControl } from "./surveycontrol/SuverControl"

export const SurveysPage = () => {
  const { isDone } = useAppSelector(state => state.survey)

  console.log(isDone)
  
  return (
    <BasicCardContainer sx={{ background: '#fff' }}>
      {!isDone && <SuverControl />}
      { isDone && <Grafic />}
    </BasicCardContainer>
  )
}
