import { Paper, Typography } from '@mui/material'
import { rootImage } from '../../../../app/core/rootImages'

export default function MessageSurvey() {
  return (
    <Paper elevation={3} sx={{ maxWidth: '600px', padding: '25px', borderRadius: '20px', minWidth: '300px', minHeight: '450px', display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center' }}>
        <img src={rootImage.avatar.default}  width='80px'/>
        <Typography variant='subtitle1'>Survey, Pleas click the button</Typography>
    </Paper>
  )
}
