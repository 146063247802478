import { Box, Grid } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/Api.config'
import { FormButton } from '../../../app/Custom'
import { PermissionsCard } from '../../../app/components/UI'
import { useAppSelector, useRequest } from '../../../app/hooks'

function AdminPermissions() {
  const { useId } = useAppSelector(state => state.auth)
  const { isLoading, sendRequest } = useRequest()
  const [sectionMenu, setSectionMenu] = useState({
    wallet: {
      section: 'wallet',
      status: true,
    },
    shop: {
      section: 'shop',
      status: true,
    },
    liveshop: {
      section: 'liveshop',
      status: true,
    },
    news: {
      section: 'news',
      status: true,
    },
    survey: {
      section: 'survey',
      status: true,
    },
    duets: {
      section: 'duets',
      status: true,
    },
    users: {
      section: 'users',
      status: true,
    },
    messages: {
      section: 'messages',
      status: true,
    },
    market: {
      section: 'market',
      status: true,
    },
    specials: {
      section: 'specials',
      status: true,
    },
    raffles: {
      section: 'raffles',
      status: true,
    },
  })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const sectionsMenu = []

    for (const value of Object.values(sectionMenu)) {
      sectionsMenu.push(value)
    }

    const sendBody = { sectionsMenu: sectionsMenu }

    await sendRequest(apiConfig.put(`/updatesection/${useId}`, sendBody))
      .then((res: any) => {
        console.log(res.data)
        toast.success('The sections has been saved successfully')
      })
      .catch((err) => console.log(err.message))
  }

  const getSections = async () => {
    await sendRequest(apiConfig.get(`/getsection/`))
      .then((res: any) => {
        const sectionData = res.data.sections
        sectionData.sections.map((item: any) => {
          const { section, status } = item
          setSectionMenu((last: any) => {
            return {
              ...last,
              [section]: {
                section,
                status: status,
              },
            }
          })
        })
      })
      .catch((err) => console.log(err.message))
  }

  useEffect(() => {
    getSections()
  }, [])  

  const DUMMY_DATA = useMemo(() => {
    const values = []

    for (const [, value] of Object.entries(sectionMenu)) {
      values.push({
        text: value.section,
        checked: value.status
      })
    }
    return values
  } ,[sectionMenu])

  return (
    <>
      <Grid container spacing={2}>
        {DUMMY_DATA.map((permissions, idx) => {
          const { text, checked } = permissions

          return (
            <Grid key={idx} item xs={12} sm={6} xl={4}>
              <Box my={2}>
                <PermissionsCard
                  txt={text}
                  name={text}
                  checked={checked}
                  id={text}
                  setSectionMenu={setSectionMenu}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <form onSubmit={(e) => onSubmit(e)}>
        <FormButton
          disabled={isLoading}
          size="large"
          type="submit"
          sx={{
            marginTop: '15px',
          }}
          loading={isLoading}
          variant="contained"
          color="secondary"
        >
          Send
        </FormButton>
      </form>
    </>
  )
}

export default AdminPermissions