import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"

console.log(process.env.REACT_APP_API_URL)

const axiosConfig: CreateAxiosDefaults = {
    baseURL: process.env.REACT_APP_API_URL || 'https://nfanst-api.devtop.online/api/v1',
    responseType: 'json',
    timeout: 3000,
    headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': "*"
    }
}

class Api {
    private token: string | null = null
    private fetcher: AxiosInstance = axios.create(axiosConfig)

    async get(url: string) {
        const response = await this.fetcher.get(url)
        return response 
    } 

    async post(url: string, body: any) {
        const response = await this.fetcher.post(url, body)
        return response
    }

    async put(url: string, body: any) {
        const response = await this.fetcher.put(url, body)
        return response
    }

    handleError (msg: string, error: any) {
        console.log(msg)
        throw new Error(error)
    }
}


export const apiConfig = new Api()