import { Box, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { apiConfig } from '../../../api/Api.config'
import { FormButton } from '../../../app/Custom'
import { useAppSelector, useRequest } from '../../../app/hooks'
import { toast } from 'react-toastify'

function AdminSurveys() {
  const  { useId } = useAppSelector(state => state.auth)
  const { isLoading, sendRequest } = useRequest()
  const [questions, setQuestions] = useState<JSX.Element[]>([])
  const [inputs, setInputs] = useState<{ [key: string]: string }>({
    title: '',
  })

  const addQuestion = () => {
    const question = <TextField fullWidth />

    setQuestions([...questions, question])
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }))
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const surveyQuestions = []
    for (const values of Object.values(inputs)) {
      surveyQuestions.push({ name: values })
    }
    surveyQuestions.shift()

    const surveyBody = {
      title: inputs.title,
      selections: surveyQuestions,
    }

    await sendRequest(apiConfig.post(`/createsurvey/${useId}`, surveyBody))
      .then((res: any) => {
        console.log(res.data)
        toast.success('The survey has been created succesfully!')
      })
      .catch((err) => console.log(err.message))
  }

  return (
    <Box bgcolor={'white'} p={4} maxWidth={'50vw'} mx={'auto'}>
      <Typography fontWeight={'bold'}>Admin Surveys</Typography>
      <form onSubmit={(e) => onSubmit(e)}>
        <Stack spacing={2} padding="25px 0" mx={'auto'}>
          <Typography fontWeight={'bold'}>Survey Title</Typography>

          <TextField fullWidth id="title" name="title" label="Title" onChange={handleInputChange} />

          <Stack spacing={2}>
            <Typography fontWeight={'bold'}>Survey Questions</Typography>
            {questions.map((_item, idx) => (
              <Box key={idx}>
                <TextField
                  fullWidth
                  id={`question${idx + 1}`}
                  name={`question${idx + 1}`}
                  label={`Question ${idx + 1}`}
                  onChange={handleInputChange}
                />
              </Box>
            ))}
          </Stack>
        </Stack>

        <Stack spacing={2}></Stack>

        <Box
          display={'flex'}
          alignContent="center"
          justifyContent={'center'}
          flexDirection="column"
          textAlign={'center'}
        >
          <FormButton
            disabled={isLoading}
            size="large"
            onClick={addQuestion}
            sx={{
              marginTop: '15px',
            }}
            loading={isLoading}
            variant="contained"
            color="secondary"
            fullWidth
          >
            add question
          </FormButton>
          <FormButton
            disabled={isLoading}
            size="large"
            type="submit"
            sx={{
              marginTop: '15px',
            }}
            loading={isLoading}
            variant="contained"
            color="secondary"
            fullWidth
          >
            Send
          </FormButton>
        </Box>
      </form>
    </Box>
  )
}
export default AdminSurveys
