import { Box, Button, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { apiConfig } from "../../../../api/Api.config"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { surveyActions } from "../../../../store/slice/survey.slice"
import MessageSurvey from "./MessageSurvey"
import { SurveyPaper } from "./SurveyPaper"

function* stepper(survey: SurveysInterface[] = []): Generator<JSX.Element> {
    let index = 0

    while (index < survey.length) {
        yield <SurveyPaper title={survey[index]?.title || ''} options={survey[index]?.selections || []}/>
        index = index + 1
    }
}

export const SuverControl = () => {
    const { currentQuestionIsResponse } = useAppSelector(state => state.survey)
    const dispatch = useAppDispatch()

    const [surveys, setSurveys] = useState<SurveysInterface[]>()
    const [currentElement, setCurrentElement] = useState<JSX.Element | null>()
    const [error, setError] = useState(false)

    const instace = useMemo(() => stepper(surveys) ,[surveys])

    useEffect(() => {
        apiConfig.get('/getallsurvey')
            .then((res) => setSurveys(res.data.data))
            .catch((err) => {
                setError(true)
                toast.error('algo salio mal')
                console.log(err)
            })


    }, [])

    const onClick = () => {
        const res = instace.next()

        if(res.done) 
            return dispatch(surveyActions.isDone())

        setCurrentElement(res.value)
        dispatch(surveyActions.setCurrentResponse(false))
    }

    return (
        <div>
            <Box>
                {currentElement && currentElement}
                {!currentElement &&  <MessageSurvey />}
            </Box>

            <Stack direction='row' justifyContent={'flex-end'}>
                <Button variant='contained' onClick={onClick} disabled={!currentQuestionIsResponse && Boolean(currentElement)}>
                    next
                </Button>
            </Stack>
        </div>
    )
}


export interface SurveysInterface {
    _id: string;
    title: string;
    selections: Selection[];
    createdByAdmin: string;
    votes: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface Selection {
    name: string;
    _id: string;
}
