import { InsertDriveFileOutlined } from '@mui/icons-material'
import { Paper, Box, Typography, Divider, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { FormButton } from '../../../../app/Custom'
import { useRequest } from '../../../../app/hooks'
import { surveyActions } from '../../../../store/slice/survey.slice'

export const SurveyPaper = ({ title, options }: Props) => {
    const dispatch = useDispatch()

    const { isLoading, sendRequest } = useRequest()


    const handleClick = () => {
        const promise = new Promise((resolve) => {
            setTimeout(() => resolve(true), 1000)
        })

        sendRequest(promise).then(() => {
            dispatch(surveyActions.setCurrentResponse(true))
        })
    }

    return (
        <Paper elevation={3} sx={{ maxWidth: '600px', padding: '25px', borderRadius: '20px', minWidth: '300px', minHeight: '450px'  }}>
            <header>
                <Box display={"flex"} mb="25px" flexDirection={"column"} >
                    <InsertDriveFileOutlined sx={{ fontSize: '85px', color: 'secondary.dark', margin: '0 auto' }} />
                    <Typography variant='body1' fontWeight={'600'} textAlign="center">Your opinion matters</Typography>
                </Box>
                <Typography variant='body1' mb="15px">{title}</Typography>
            </header>

            <Divider />
            <Typography variant='body1' textAlign={"center"} mt="15px" fontWeight={'600'}>Your Rating:</Typography>

            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Very-good"
                    name="radio-buttons-group"
                    color="secondary"
                >
                    {
                        options.length > 0 && options.map(({ _id, name }, key) => (
                            <FormControlLabel id={_id} value={_id} control={<Radio color="secondary" />} label={name} key={key}/>
                        ))
                    }
                </RadioGroup>
            </FormControl>

            <Divider />
            <Box mt="15px" display="flex" justifyContent={'flex-end'}>
                
                <FormButton 
                    variant='contained'
                    loading={isLoading} 
                    onClick={handleClick}
                    color='secondary'>Select Response</FormButton>
            
            </Box>
        </Paper>
    )
}

interface Props {
    title: string
    options: Selection[]
}

export interface Selection {
    name: string;
    _id: string;
}
