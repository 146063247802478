import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  openSideBar: false,
  urlPermision: {
    wallet: {
      section: 'wallet',
      status: true,
    },
    shop: {
      section: 'shop',
      status: true,
    },
    liveshop: {
      section: 'liveshop',
      status: true,
    },
    news: {
      section: 'news',
      status: true,
    },
    survey: {
      section: 'survey',
      status: true,
    },
    duets: {
      section: 'duets',
      status: true,
    },
    users: {
      section: 'users',
      status: true,
    },
    messages: {
      section: 'messages',
      status: true,
    },
    market: {
      section: 'market',
      status: true,
    },
    specials: {
      section: 'specials',
      status: true,
    },
    raffles: {
      section: 'raffles',
      status: true,
    },
  }
}

const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.openSideBar = !state.openSideBar
    },
    setURLPermision(state, { payload }) {
        const { section, status } = payload
        const { urlPermision } = state as any

        urlPermision[section] = {
          section,
          status
        }

        state.urlPermision = urlPermision
    }
  },
})

export const UIActions = UISlice.actions
export const UIReducer = UISlice.reducer
