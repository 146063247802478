import { createSlice} from '@reduxjs/toolkit'

const initialState = {
   currentQuestionIsResponse: false,
   isDone: false
}

const surveySlice = createSlice({
   name: 'survey',
   initialState,
   reducers: {
    setCurrentResponse (state, { payload }) {
        state.currentQuestionIsResponse = payload
    }, 
    isDone(state) {
        state.isDone = true
    }
   }
 })

export const surveyActions = surveySlice.actions
export const surveyReducer = surveySlice.reducer