import React from "react"
import { useMemo } from "react"
import { Box, Grid, GridProps, styled } from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"
import { useAppSelector } from "../../../../app/hooks"
import { CardTegnology } from "./CardTecnology"

export const TegnologyContainer = React.memo(() => {
  const { urlPermision } = useAppSelector(state => state.ui)

  const urlsActive = useMemo(() => [urlPermision.news, urlPermision.liveshop, urlPermision.survey, urlPermision.market, urlPermision.users].filter(({ status }) => status), [urlPermision])
  
  const minActive = urlsActive.length >= 3
  const size = 12 / urlsActive.length 

  return (
    <Box display={minActive ? 'block' : 'none'}>
      <GridCustom container >
        <CardTegnology
          link="/dashboard/my-profile"
          disabled={!urlPermision.users.status}
          size={size}
          url={rootImage.icons.user}
          text={"My Profile"} />
        <CardTegnology
          link="/dashboard/news"
          disabled={!urlPermision.news.status}
          size={size}
          url={rootImage.icons.dasboard.news}
          text={"News"} />
        <CardTegnology
          link="/dashboard/live"
          disabled={!urlPermision.liveshop.status}
          size={size}
          url={rootImage.icons["life-shoping-icon"]}
          text={"Live shopping"} />
        <CardTegnology
          link="/dashboard/survey"
          disabled={!urlPermision.survey.status}
          size={size}
          url={rootImage.icons["surveys-icon"]}
          text={"surveys"} />
        <CardTegnology
          link="https://marketplacenfanst.devtop.online/" target="_blank"
          disabled={!urlPermision.market.status}
          size={size}
          url={rootImage.icons.dasboard.onlineStore}
          text={"MARKETPLACE"} />
      </GridCustom>
    </Box>
  )
})

const GridCustom = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "25px 50px",
  borderRadius: "25px",
  marginTop: "25px",
  rowGap: "25px",
  [theme.breakpoints.up("md")]: {
    padding: "25px 50px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "25px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px  5px",
  },
}))
